
import { Component, Vue } from "vue-property-decorator";
import Wysiwyg from "@/components/reusable/Wysiwyg.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Icon from "@/components/reusable/Icon.vue";
//import Toast from "@/components/reusable/Toast.vue";
import MfrMenu from "@/components/manufacturer/MfrMenu.vue";
import { MfrModel } from "@/models/mfr";
import MfrService from "@/services/mfr_service";
import UIkit from "uikit";
import { EventBus } from "@/events/index";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { ListsActions } from "@/store/modules/lists/types";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { AuthError, NotFoundError } from "@/services/error_service";
import ImagePicker from "@/components/reusable/ImagePicker.vue";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
import { AssetModel } from "@/models/asset";

Component.registerHooks(["beforeRouteLeave"]);
@Component({
  components: {
    Wysiwyg,
    FormInput,
    Icon,
    //    Toast,
    MfrMenu,
    ImagePicker
  }
})
export default class MfrEditor extends Vue {
  protected showWarningModal = false;
  protected mfr = {} as MfrModel;
  protected isNew = false;
  protected mfrService = new MfrService();
  protected id = 0;
  protected deleteData: MfrModel[] = [];
  protected toast = false;
  protected messageHtml = "";
  protected className = "success";

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  @Validations()
  validations() {
    return {
      mfr: {
        display_name: { required }
      }
    };
  }

  created() {
    if (!this.$route.params.id) {
      this.isNew = true;
    } else {
      this.id = parseInt(this.$route.params.id, 10);
      this.getSingleMfr();
    }
  }

  mounted() {
    EventBus.$on(
      "sendImage",
      (files: AssetModel[]) => (this.mfr.image = files[0])
    );
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: MfrModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "manufacturer" },
        { height: "auto", adaptive: true }
      );
      //   UIkit.modal(
      //     document.getElementById("delete-modal") as HTMLElement
      //   ).show();
      // });
    });
  }
  beforeDestroy() {
    EventBus.$off("sendImage");
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = ["#delete-modal", "#save-modal"];
    modals.forEach(selector => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.showWarningModal) {
      UIkit.modal
        .confirm(
          `
    <div class="uk-modal-header uk-flex uk-flex-middle">
      <div class="uk-flex-none">
        <span
          uk-icon="icon: warning; ratio:1.5;"
          class="red no-hover uk-margin-small-right"
        ></span>
      </div>
      <div>
        <h2 class="uk-modal-title uk-margin-remove">
          You have not saved your changes!
        </h2>
      </div>
    </div>
    <div class="uk-modal-body">
      Would you like to continue without saving your changes?
    </div>`
        )
        .then(
          function() {
            next();
          },
          function() {
            next(false);
          }
        );
    } else {
      next();
    }
  }

  protected showWarning(isVisible: boolean): void {
    this.showWarningModal = isVisible;
  }

  protected cancel(): void {
    this.$router.go(-1);
  }

  protected closeToast(): void {
    this.toast = false;
  }

  protected showToast(message: string, className: string): void {
    this.messageHtml = message;
    this.className = className;
    this.toast = true;
  }

  protected save(): void {
    this.$v.mfr.$touch();
    if (!this.$v.$invalid) {
      this.showWarningModal = false;
      if (this.isNew) {
        this.postNew();
      } else {
        this.saveExisting();
      }
    }
  }

  protected saveKeypress(): void {
    this.save();
  }
  protected get website(): string {
    return process.env.VUE_APP_C5_URL;
  }

  protected async getSingleMfr(): Promise<void> {
    this.setLoading(true);
    try {
      const res: MfrModel = await this.mfrService.getSingleMfr(this.id);
      this.mfr = res;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof NotFoundError) {
        this.$router.replace({
          name: "NotFound",
          query: { error: encodeURI(err.message) }
        });
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    this.setLoading(false);
  }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    this.showWarningModal = false;
    try {
      await this.mfrService.deleteMfr(id);
      this.$router.push({
        path: "/manufacturer",
        query: {
          deleted: encodeURI(`${this.mfr.display_name}`),
          type: "manufacturer"
        }
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected buildRequest(): MfrModel {
    const req = { ...this.mfr };
    if (this.mfr.image && typeof this.mfr.image !== "number") {
      req.image = (this.mfr.image as AssetModel).id;
    }
    return req as MfrModel;
  }

  protected async postNew(): Promise<void> {
    const req = this.buildRequest();
    try {
      const res = await this.mfrService.createNewMfr(req);
      this.mfr = res;
      this.$router.push({
        path: "/manufacturer/edit/" + res.id,
        query: {
          created: encodeURI(`${this.mfr.display_name}`),
          type: "manufacturer"
        }
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async saveExisting(): Promise<void> {
    const req = this.buildRequest();
    try {
      await this.mfrService.saveMfr(req, this.mfr.id as number);
      if (this.$route.query.created) {
        this.$router.push({ query: {} });
      }
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Manufacturer <strong>${this.mfr.display_name}</strong> has been ${
          this.action
        }. <a href="${this.website}/manufacturers/${this.mfr.handle!.replace(
          "_",
          "-"
        )}" target="_blank">View Live</a>`,
        []
      );
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  get action(): string {
    let action = "saved";
    if (this.isNew) {
      action = "created";
    }
    return action;
  }
}
